import {useMemo} from 'react';
import {useDevice} from '@lookiero/aurora';

import {useMendelContext} from '@/modules/ABTesting';
import {getSteps} from '../services/howItWorkService';
import type {Step} from '@/components/atoms';

export const useSteps = (): Step[] => {
  const {screen} = useDevice();

  const {freeTrial, nicaOnHP} = useMendelContext();

  return useMemo(() => {
    const experiment = nicaOnHP.srcHowItWorks?.experiment;
    const variation = nicaOnHP.srcHowItWorks?.variation;
    const text = freeTrial.text?.howItWorks;
    const [budget, ...rest] = getSteps(screen.L, experiment, variation);
    const newBudget = budget ? [{...budget, summary: text ? {id: text} : budget.summary}] : [];
    return [...newBudget, ...rest];
  }, [screen.L, freeTrial, nicaOnHP]);
};
